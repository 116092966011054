/* eslint quote-props: 0 */  // --> OFF
/* eslint quotes: 0 */  // --> OFF
import { StringTableCommon } from './common/StringTableCommon'
export const StringTable = [
  ...StringTableCommon,
  {
    "key": "sampleStrKey",
    "en": "Sample"
  },
  {
    "key": "camNotFound",
    "en": "Camera not installed here, please contact the customer care"
  },
  {
    "key": "noEventsFound",
    "en": "No events found"
  },
  {
    "key": "defaultErrorMsg",
    "en": "An error occurred, please try again. Contact customer care if problem persists"
  },
  {
    "key": "noEventFields",
    "en": "Please select event fields"
  },
  {
    "key": "downloadEventsWithNoImages",
    "en": "Dowload pdf/xlsx (Event images not present)"
  },
  {
    "key": "downloadEventsWithImages",
    "en": "Save page as pdf (Event images present)"
  },
  {
    "key": "noItemsFound",
    "en": "No items to show here"
  },
  {
    "key": "permissionError",
    "en": "You may not have the required access permissions. "
  },
  {
    "key": "emptyView",
    "en": "Nothing to show here yet"
  },
  {
    "key": "noUsers",
    "en": "No members added yet"
  },
  {
    "key": "noVideoStream",
    "en": "Video stream not available"
  },
  {
    "key":  "enterEmailPwd",
    "en": "Please enter email and password"
  },
  {
    "key": "settingsUpdated",
    "en": "Successfully configured settings"
  },
  {
    "key": "noCameras",
    "en": "Camera not installed at this place. Please contact the customer care"
  },
  {
    "key": "noDevices",
    "en": "No devices added yet"
  },
]