/* eslint-disable */ 
import React, { Component } from 'react';
import {
  css
} from 'aphrodite';
import { Link } from 'react-router-dom';
import { Styles } from './Styles'
import { CommonStyles } from '../../styles/Styles';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { AppHelper } from '../../helpers/AppHelper';
import { Controller } from '../../common/Controller';
import { settings, relativesettings, relativesettingsLess, relativeImageSliderSettings } from './SliderSettings';
import { SearchTextField } from './SearchTextField';
import { AppConfig } from '../../AppConfig';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Radio from '@material-ui/core/Radio';
import TextField from '@material-ui/core/TextField';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.canvas = React.createRef()    
    this.imageRef = React.createRef()                 
    this.state = {
      player: 'play_arrow',
      selectedImageIndex: 0,
      selectedRelatedEventIndex: 0,
      comment:"",
      commentType:"",
      seniorManagerReview:this.props.selectedEditItem && this.props.selectedEditItem.latestReviewsBySeniorManager && this.props.selectedEditItem.latestReviewsBySeniorManager[0] ?this.props.selectedEditItem.latestReviewsBySeniorManager[0] : [] ,
      managerReview:this.props.selectedEditItem && this.props.selectedEditItem.latestReviewsByManager && this.props.selectedEditItem.latestReviewsByManager[0] ? this.props.selectedEditItem.latestReviewsByManager[0] :[],
      selectedEditItem:this.props.selectedEditItem,
      openManagerReview:false,
      openSeniorManagerReview:false,
      eventInfoImgeHeight : 0,
      eventInfoImgeWidth : 0,
      showCanvas : false,
      imageInfo:"",
    }
    this.pause = true;
    this.play = false;
    this.playerFnId = null;
    this.reviews = []
    this.openCommentsModal = this.openCommentsModal.bind(this)
  }

  searchBox = () => {
    return (
      <SearchTextField
        searchHandler={this.props.searchHandler} />
    )
  }

  showRelatedImages = (index) => {
    this.setState({
      selectedImageIndex: index,
      relatedEvent: false
    })
    this.props.imageSliderHandler(true)
  }

  closeRelatedImages = () => {
    this.setState({ selectedImageIndex: 0 })
    this.props.imageSliderHandler(false)
  }

  showRelatedEvents = (index, item) => {
    this.setState({
      relatedEvent: true,
      selectedRelatedEventIndex: index,
    })
    this.props.imageSliderHandler(false)
    this.props.onRelatedEventTileClick(item)
  }

  closeRelatedEvent = () => {
    this.setState({ relatedEvent: false, selectedRelatedEventIndex: 0 })
  }

  renderEventHeader = (item) => {
    return (
      <div className={this.props.relativeImageSliderMode ? css(Styles.eventItemHeaderConatinerRelativeOpen) : css(Styles.eventItemHeaderConatinerRelativeClose)}>
        <div className={css(Styles.eventHeaderOuterContent)}>
          <div className={css(Styles.eventItemHeaderInnerContent)}>

            {this.props.searchHandler &&
              <div className={css(Styles.eventItemHeaderSearchInnerContent)}>
                {this.searchBox()}
              </div>
            }

            {item &&
              <p className={css(Styles.eventItemTime)}>{moment(item.event_time).format('MMM DD, YYYY, h:mm:ss A')}</p>
            }

            {item &&
              <p className={css(Styles.eventItemTitle)}>{item.name}</p>
            }

            {item &&
              <div className={css(Styles.eventItemInnerContent)}>
                <div className={css(Styles.eventItemOuterTagContent)}>

                  <div className={css(Styles.eventItemInnerTagContent)}>
                    {item.type &&
                      <div className={css(Styles.eventItemType)}>{item.type}</div>
                    }

                    <div className={css(Styles.eventItemType, Styles.eventItemTypePriority)}>
                      {AppHelper.getPriorityStr(item.priority)}
                    </div>

                  </div>
                </div>

                {item && item.tags &&
                  <div className={css(Styles.eventEditContainer, Styles.eventEditContainerStyle, Styles.tagContainerHeight)}>
                    {Object.keys(item.tags).map(key => {
                      return (
                        <div className={css(Styles.tagContainer)}>
                          <p className={css(Styles.tagKey)}>{key}</p>
                          <p className={css(Styles.tagValue)}>{item.tags[key]}</p>
                        </div>
                      )
                    })}
                  </div>
                }

              </div>
            }
          </div>
        </div>
      </div>
    )
  }

  processImages = (item) => {
    let eventImages = [item.image]
    if (item.additionalImages && item.additionalImages.length > 0) {
      eventImages = eventImages.concat(item.additionalImages)
    }
    return eventImages
  }

  renderRelativeImageSlider = (item) => {
    if (!item) return

    const eventImages = this.processImages(item)
    if (this.props.relativeImageSliderMode) {
      return (
        <div className={css(Styles.imageSliderContainer)}>
          {this.props.relativeImageSliderMode &&
            <div className={css(Styles.eventLiveFeed)} onClick={() => this.closeRelatedImages()}>
              <img src={require('../../assets/images/close-img-white.svg')} className={css(CommonStyles.addImg)} />
            </div>
          }

          <Slider
            {...relativeImageSliderSettings}
            className={css(Styles.slider)}
            initialSlide={this.state.selectedImageIndex} >
            {eventImages.length > 0 &&
              eventImages.map(option => {
                return (
                  <div className={css(Styles.eventImageSpecContainer)}>
                    <img
                      src={option}
                      key={option}
                      className={css(Styles.eventImageSpec)} />
                  </div>
                )
              })}
          </Slider>
        </div>
      )
    }
  }


  onPlay = () => {
    if (this.play) {
      this.play = false;
      this.pause = true;
      this.setState({
        player: 'play_arrow'
      })
      if (this.playerFnId) {
        clearInterval(this.playerFnId);
      }
      this.playerFnId = null
    } else {
      this.pause = false;
      this.play = true;
      this.setState({
        player: 'pause'
      })
      this.playerFnId = setInterval(() => {
        this.playEvent();
      }, AppConfig.jpegPlayerRefreshInterval);
    }
  }

  playEvent = () => {
    if (this.props.eventIndex === (this.props.eventList.length - 1)) {
      if (this.play) {
        this.onPlay()
      }
      return;
    }
    this.props.onEventTileSelect(this.props.eventList[this.props.eventIndex + 1], this.props.eventIndex + 1)
  }

  eventNext = () => {
    this.props.onEventTileSelect(this.props.eventList[this.props.eventIndex + 1], this.props.eventIndex + 1)
  }

  eventBefore = () => {
    this.props.onEventTileSelect(this.props.eventList[this.props.eventIndex - 1], this.props.eventIndex - 1)
  }

  handleClickOpen = () => {
    this.setState({
    openCommentsModal :true
    })  
  };

  handleClose = () => {
   this.setState(
     {
       openCommentsModal: false,
     },
     () => {
       this.setState({
         commentType: "",
         comment: "",
       });
     }
   );
  };

saveComments = () =>{
  if (this.state.commentType) {
    let org = Controller.get().userMgr().getCurrentOrg();

    let tags = {};
    if (this.props.selectedEditItem.tags) {
      tags = this.props.selectedEditItem.tags;
    }

    if (org.managerType == "PROJECT_MANAGER") {
      tags["project_manager_review"] = this.state.commentType;
    } else {
      tags["org_manager_review"] = this.state.commentType;
    }
    
    let user = Controller.get().userMgr().getUser();
    let data = {
      selectedEditItem: this.props.selectedEditItem,
      comment: this.state.comment,
      state: this.state.commentType,
      userid: user._id,
      timestamp: new Date().toString(),
      name: user.name,
      email: user.email,
      roles: org.roles,
      managerType: org.managerType,
      tags: tags,
    };

    this.props.editEvent(data, "addComment", ()=>{
    // this.props.refresh();
    // setTimeout(() => {
      this.setState({
        seniorManagerReview:
          this.props.selectedEditItem.latestReviewsBySeniorManager[0],
        managerReview: this.props.selectedEditItem.latestReviewsByManager[0],
      });
    // }, 1000);
    this.handleClose();
});

  }
}

handleChange = (event) => {
    this.setState({
    commentType : event.target.value
    })
  };

handleCommentChange = (event) =>{
    this.setState({
    comment : event.target.value
    })
}

addNewComment  = () =>{
return (

<Box  sx={{ width: '89%',   flexDirection: 'column',  border: '1px solid rgb(91, 78, 247) !important', margin:'5.5%' ,paddingBottom:"2%",}}>
<Box sx={{    flexDirection: 'column' }}>

     <Radio
        className={css(Styles.reviewStatusRadio)}
        checked={this.state.commentType == "Okayed"}
        onChange={this.handleChange}
        value="Okayed"
      />{" "}
      <span className={css(Styles.textForReview)}>Okayed</span>
      {/* </Box>
<Box sx={{ width: '100%' }}> */}

      <Radio
        className={css(Styles.reviewStatusRadio)}
        checked={this.state.commentType == "Escalated"}
        onChange={this.handleChange}
        value="Escalated"
      />{" "}
      <span className={css(Styles.textForReview)}>Escalated</span>

</Box>
<Box sx={{marginLeft:"2.5%"}}>
    <textarea
        InputProps={{ disableUnderline: true }}
        rows="5" cols="10" 
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          borderRadius: "4px",
          resize:'none',
          width: "90%",
        }}
        value={this.state.comment}
        placeholder="Comments"
        onInput={this.handleCommentChange}
      />
</Box>
    <Box sx={{ marginLeft: "2.5%", display: "flex", justifyContent: "center", width: "90%" }}>
      <Button
        variant='contained'
        onClick={this.saveComments}
        className={css(Styles.buttonStyle)}
      >
        SAVE
      </Button>
    </Box>
    <Box sx={{ marginLeft: "2.5%", display: "flex", justifyContent: "center", width: "90%" }}>
      <p style={{ color: "#fff" }}>{this.props.commentErr}</p>
    </Box>
  </Box>

);}

detectChangeInEvent = () =>{
  if(this.props.selectedEditItem && this.props.selectedEditItem._id != this.state.selectedEditItem._id){
      this.setState({
          seniorManagerReview:this.props.selectedEditItem.latestReviewsBySeniorManager[0],
          managerReview:this.props.selectedEditItem.latestReviewsByManager[0],
          selectedEditItem:this.props.selectedEditItem
      }, ()=>{
      })
  }
  return this.optionForComments()
}


optionForComments  = () =>{
  if (
    !this.props.selectedEditItem ||
    this.props.selectedEditItem.reviews.length == 0
  ) {
    return;
  }
  this.reviews = this.props.selectedEditItem.reviews;
  let timeStampForSeniorManager = this.props.selectedEditItem
    .latestReviewsBySeniorManager[0]
    ? this.props.selectedEditItem.latestReviewsBySeniorManager[0].timestamp
    : "";
  let timeStampForManager = this.props.selectedEditItem
    .latestReviewsByManager[0]
    ? this.props.selectedEditItem.latestReviewsByManager[0].timestamp
    : "";
  this.reviews = [];
  this.reviews = JSON.parse(
    JSON.stringify(this.props.selectedEditItem.reviews)
  );
  this.reviews.sort(function (a, b) {
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
  return this.reviews.map((element, index) => {
    return (
      <ListItem key={index}>
        <Grid container spacing={2} style={{ marginTop: "4%" }}>
          <Grid item xs={1}>
            <Radio
              checked={
                element.state == "Okayed"
                  ? element.state == "Okayed"
                  : this.state.commentType == "Okayed" && index == 0
              }
              disabled={element.state}
              value="Okayed"
            />{" "}
            Okayed
          </Grid>

          <Grid item xs={1}>
            <Radio
              checked={
                element.state == "Escalated"
                  ? element.state == "Escalated"
                  : this.state.commentType == "Escalated" && index == 0
              }
              value="Escalated"
              disabled={element.state}
            />{" "}
            Escalated
          </Grid>

          <Grid item xs={5}>
            <TextField
              id="filled-full-width"
              label="Comments"
              disabled={element.comment}
              style={{ margin: 8 }}
              value={element.comment ? element.comment : this.state.comment}
              placeholder="Comments"
              fullWidth
            />
          </Grid>

          <Grid item xs={1}>
            <TextField
              id="filled-full-width"
              disabled
              value={
                element.name
                  ? element.name
                  : element.email
              }
              label="Name "
              style={{ margin: 8 }}
              fullWidth
            />
          </Grid>

          <Grid item xs={3}>
            <TextField
              id="filled-full-width"
              disabled
              value={moment(element.timestamp).format(
                "MMM DD, YYYY, h:mm:ss A"
              )}
              label="Date"
              style={{ margin: 8 }}
            />
          </Grid>
        </Grid>
      </ListItem>
    );
  });

}

showLatestReviews = () =>{
  if((this.props.selectedEditItem && this.props.selectedEditItem.latestReviewsByManager && this.props.selectedEditItem.latestReviewsByManager.length >0 )||(this.props.selectedEditItem && this.props.selectedEditItem.latestReviewsBySeniorManager&& this.props.selectedEditItem.latestReviewsBySeniorManager.length >0)){
  return (
    <Box m={1} p={2}>
      <Box width="100%">
        <Card className={css(Styles.reviewCard)}>
          {this.state.seniorManagerReview ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >
              <Typography variant="p" component="p">
                Org Manager Review
              </Typography>

              <IconButton
                onClick={() =>
                  this.setState({
                    openSeniorManagerReview:
                      !this.state.openSeniorManagerReview,
                  })
                }
                aria-label="show more"
              >
                <ExpandMoreIcon style={{ color: "rgb(255, 255, 255)" }} />
              </IconButton>
            </CardActions>
          ) : (
            ""
          )}
          <Divider
            style={{ backgroundColor: "rgb(255, 255, 255)", height: "1px" }}
          />
          {this.state.seniorManagerReview ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >
              <Tooltip title={this.state.seniorManagerReview &&
                this.state.seniorManagerReview.name
                ? "    " + this.state.seniorManagerReview.name
                : ""}>
                <Typography noWrap >
                  {this.state.seniorManagerReview &&
                    this.state.seniorManagerReview.name
                    ? "    " + this.state.seniorManagerReview.name
                    : ""}{" "}
                </Typography></Tooltip>
              <Typography>
                {this.state.seniorManagerReview.state
                  ? "   " + this.state.seniorManagerReview.state
                  : ""}
              </Typography>
              <Tooltip title={this.state.seniorManagerReview.timestamp
                ? "    " +
                moment(this.state.seniorManagerReview.timestamp).format(
                  "MMM DD, YYYY, h:mm:ss A"
                )
                : ""}>
                <Typography noWrap >
                  {" "}
                  {this.state.seniorManagerReview.timestamp
                    ? "    " +
                    moment(this.state.seniorManagerReview.timestamp).format(
                      "MMM DD, YYYY, h:mm:ss A"
                    )
                    : ""}
                </Typography></Tooltip>
            </CardActions>
          ) : (
            ""
          )}

          {this.state.seniorManagerReview ? (
            <Collapse
              in={this.state.openSeniorManagerReview}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Divider />
                <Typography  style={{ wordWrap: "break-word" }} paragraph>
                  {this.state.seniorManagerReview.comment
                    ? " " + this.state.seniorManagerReview.comment
                    : ""}
                </Typography>
              </CardContent>
            </Collapse>
          ) : (
            ""
          )}

          <Divider
            style={{ backgroundColor: "rgb(255, 255, 255)", height: "1px" }}
          />

          {this.state.managerReview ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >
              <Typography variant="p" component="p">
                Project Manager Review
              </Typography>

              <IconButton
                onClick={() =>
                  this.setState({
                    openManagerReview: !this.state.openManagerReview,
                  })
                }
                aria-label="show more"
              >
                <ExpandMoreIcon style={{ color: "rgb(255, 255, 255)" }} />
              </IconButton>
            </CardActions>
          ) : (
            ""
          )}

          <Divider
            style={{ backgroundColor: "rgb(255, 255, 255)", height: "1px" }}
          />

          {this.state.managerReview ? (
            <CardActions
              display="flex"
              style={{ justifyContent: "space-between" }}
            >  
              <Tooltip title={this.state.managerReview && this.state.managerReview.name
                  ? "    " + this.state.managerReview.name
                  : ""}>
              <Typography noWrap >
                {this.state.managerReview && this.state.managerReview.name
                  ? "    " + this.state.managerReview.name
                  : ""}{" "}
              </Typography>
              </Tooltip>
              <Typography>
                {this.state.managerReview.state
                  ? "   " + this.state.managerReview.state
                  : ""}
              </Typography>
              <Tooltip title={this.state.managerReview.timestamp
                ? "    " +
                moment(this.state.managerReview.timestamp).format(
                  "MMM DD, YYYY, h:mm:ss A"
                )
                : ""}>
                <Typography noWrap>
                  {" "}
                  {this.state.managerReview.timestamp
                    ? "    " +
                    moment(this.state.managerReview.timestamp).format(
                      "MMM DD, YYYY, h:mm:ss A"
                    )
                    : ""}
                </Typography></Tooltip>
            </CardActions>
          ) : (
            ""
          )}

          {this.state.managerReview ? (
            <Collapse
              in={this.state.openManagerReview}
              timeout="auto"
              unmountOnExit
            >
              <CardContent>
                <Divider />
                <Typography  style={{ wordWrap: "break-word" }} paragraph>
                  {this.state.managerReview.comment
                    ? " " + this.state.managerReview.comment
                    : ""}
                </Typography>
              </CardContent>
            </Collapse>
          ) : (
            ""
          )}
        </Card>
      </Box>
    </Box>
  );
}
}
  openCommentsModal(){
  return (
    <div>
      <Dialog fullScreen open={this.state.openCommentsModal} onClose={this.handleClose} TransitionComponent={Transition}>
        <AppBar >
          <Toolbar style={{justifyContent: "space-between"}}>
            <Typography variant="h6">
              Comments
            </Typography>
                <IconButton edge="end" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <List>
        {this.detectChangeInEvent()}
          <Divider />
        </List>
      </Dialog>
    </div>
  );

}

  renderEventControls = (item) => {
    if (!item) return

    return (
      <div className={this.props.relativeImageSliderMode ? css(Styles.eventControlContentRelativeOpen) : css(Styles.eventControlContent)} >
        <div className={css(Styles.eventInnerControlContent)}>
          <div className={css(Styles.eventInnerControl)}>
            <div
              className={
                this.props.eventIndex === 0 ?
                  css(Styles.eventInnerControlImgConatinerLast) :
                  css(Styles.eventInnerControlImgConatiner)
              }
              onClick={() => this.eventBefore(item)}>
              <Icon className={css(Styles.eventPlayerIcon)}>
                skip_previous
              </Icon>
            </div>
            <p className={css(Styles.eventInnerControlImgTitle)}>Previous Event</p>
          </div>
          <div className={css(Styles.eventInnerControl)}>
            <div
              className={
                this.props.eventIndex === (this.props.eventList.length - 1) ?
                  css(Styles.eventInnerControlImgConatinerLast) :
                  css(Styles.eventInnerControlImgConatiner)
              }
              onClick={() => this.onPlay()}>
              <Icon className={css(Styles.eventPlayerIcon)}>
                {this.state.player}
              </Icon>
            </div>
            <p className={css(Styles.eventInnerControlImgTitle)}>Pause Slideshow</p>
          </div>
          <div className={css(Styles.eventInnerControl)}>
            <div
              className={
                this.props.eventIndex === (this.props.eventList.length - 1) ?
                  css(Styles.eventInnerControlImgConatinerLast) :
                  css(Styles.eventInnerControlImgConatiner)
              }
              onClick={() => this.eventNext()}>
              <Icon className={css(Styles.eventPlayerIcon)}>
                skip_next
              </Icon>
            </div>
            <p className={css(Styles.eventInnerControlImgTitle)}>Next Event</p>
          </div>
        </div>
      </div>
    )
  }

  renderEventEditContainer = (item) => {
    if (!item) return

    return (
      <div className={css(Styles.eventEditOuterContainer)}>
        <div className={css(Styles.eventEditContainer)}>
          {Controller.get().userMgr().isAdminUser() &&
            <Tooltip title={'EDIT EVENT'}>
              <div className={css(Styles.eventEditImgContainer)}>
                <div className={css(Styles.eventEditImg)} onClick={() => this.props.showEventEditor(item)}>
                  <img src={require('../../assets/images/edit-img.svg')} />
                </div>
                {/*<p className={css(Styles.eventInnerControlImgTitle, Styles.eventEdit)}>Edit</p>*/}
              </div>
            </Tooltip>
          }
          {!this.props.independentEventDetails && this.props.selectedEvent && this.props.selectedEvent._id &&
          <div style={{display:"flex"}}>
            <Tooltip title={'EVENT DETAILS'}>
              <Link
                to={{
                  pathname: '/events/' + this.props.selectedEvent._id,
                }}
                target={'_blank'}
                className={css(Styles.link)}>
                <div className={css(Styles.eventEditImgContainer)}>
                  <div className={css(Styles.eventEditImg)}>
                    <Icon
                      className={css(Styles.openEventIcon)}>
                      open_in_new
                    </Icon>
                  </div>
                </div>
              </Link>
            </Tooltip>
         { AppConfig.allowEventReviews?  <Tooltip title={'COMMENT DETAILS'}>
                <div className={css(Styles.eventEditImgContainer)}>
                  <div className={css(Styles.eventEditImg)}>
                    <Icon  onClick={()=>this.handleClickOpen()}
                      className={css(Styles.openEventIcon)}>
                      add_comment
                    </Icon>
                  </div>
                </div>
            </Tooltip>:""}
</div>
          }
        </div>
        {
          this.props.liveUrl && this.props.camId &&
          <div className={css(Styles.eventLiveFeedContainer)}>
            <Link
              to={{
                pathname: '/places/' + this.props.placeId + '/cams/' + this.props.camId,
                state: {
                  eventId: this.props.independentEventDetails && this.props.selectedEvent && this.props.selectedEvent._id
                } // To decide back path name
              }}
              className={css(Styles.link)}>
              <div className={css(Styles.eventLiveFeedContent)}>
                <p className={css(CommonStyles.margin)}>VIEW LIVE FEED</p></div>
            </Link>
          </div>
        }
      </div >
    )
  }

  renderEvenImageSliderMini = (item) => {
    if (!item) return
    const eventImages = this.processImages(item)
    if (eventImages && eventImages.length > 0) {
      let setting = {}
      let relativeLess = false
      let marginLeftRequired = false
      if (eventImages.length < 3) {
        marginLeftRequired = true
      }
      if (eventImages.length < 2) {
        setting = relativesettingsLess
        relativeLess = true
      } else {
        setting = relativesettings
      }
      return (
        <div >
          <div className={css(Styles.relatedImageHeaderContainer)}>
            <p className={css(Styles.relatedImageTitile)}>Related Images &nbsp;({eventImages.length})</p>
          </div>
          {
            this.props.independentEventDetails &&
            <Grid container spacing={2} className={css(Styles.gridImageContainer)}  >
              {eventImages.length > 0 &&
                eventImages.map((imageUrl, index) => {
                  return (
                    <Grid item xs={6} sm={6}>
                      <div
                        className={relativeLess ? css(Styles.eventRelativeImage, Styles.eventRelativeImageLess) : css(Styles.eventRelativeImage)}
                        onClick={() => this.showRelatedImages(index)}>
                        <div className={(this.state.selectedImageIndex === index) ?
                          css(Styles.eventRelativeImageContent, Styles.highlightRelative) : css(Styles.eventRelativeImageContent)}>
                          <img
                            src={imageUrl}
                            key={imageUrl}
                            onError={(e) => {
                              e.target.src = require('../../assets/images/error2.svg')
                            }}
                            className={css(Styles.relativeImageContent)} />
                        </div>
                      </div>
                    </Grid>
                  )
                })

              }
            </Grid>
          }
          {!this.props.independentEventDetails &&
            <div>
              <Slider {...setting}
                className={marginLeftRequired ? css(Styles.relativeSettingsWithMargin) : css(Styles.relativeSettings)}>
                {eventImages.length > 0 &&
                  eventImages.map((imageUrl, index) => {
                    return (
                      <div
                        className={relativeLess ? css(Styles.eventRelativeImage, Styles.eventRelativeImageLess) : css(Styles.eventRelativeImage)}
                        onClick={() => this.showRelatedImages(index)}>
                        <div className={(this.state.selectedImageIndex === index) ?
                          css(Styles.eventRelativeImageContent, Styles.highlightRelative) : css(Styles.eventRelativeImageContent)}>
                          <img
                            src={imageUrl}
                            key={imageUrl}
                            onError={(e) => {
                              e.target.src = require('../../assets/images/error2.svg')
                            }}
                            className={css(Styles.relativeImageContent)} />
                        </div>
                      </div>
                    )
                  })}
              </Slider>
            </div>
          }
        </div>
      )
    }
    return ''
  }

  renderEventSliderMini = (item) => {
    if (!item) return

    let eventsRel = [item]

    if (this.props.eventsByGroupId[item.groupId] &&
      this.props.eventsByGroupId[item.groupId].length > 0) {

      eventsRel = eventsRel.concat(this.props.eventsByGroupId[item.groupId])

      let setting = {}
      let relativeLess = false

      if (eventsRel.length < 2) {
        setting = relativesettingsLess
        relativeLess = true
      } else {
        setting = relativesettings
      }

      let marginLeftRequired = false
      if (eventsRel.length < 3) {
        marginLeftRequired = true
      }

      return (
        <div>
          <div className={css(Styles.relatedImageHeaderContainer)}>
            <p className={css(Styles.relatedImageTitile)}>Related Events &nbsp;({eventsRel.length})</p>
          </div>
          <Slider {...setting}
            className={marginLeftRequired ? css(Styles.relativeSettingsWithMargin) : css(Styles.relativeSettings)}>
            {eventsRel.map((option, index) => {
              return (
                <div
                  className={relativeLess ? css(Styles.eventRelativeImage, Styles.eventRelativeImageLess) : css(Styles.eventRelativeImage)}
                  onClick={() => this.showRelatedEvents(index, option)}>
                  <div
                    className={(this.state.selectedRelatedEventIndex === (index)) ?
                      css(Styles.eventRelativeImageContent, Styles.highlightRelative) : css(Styles.eventRelativeImageContent)}>
                    <img
                      src={option.image}
                      key={option.image}
                      onError={(e) => {
                        e.target.src = require('../../assets/images/error2.svg')
                      }}
                      className={css(Styles.relativeImageContent)}></img>
                    <div className={css(Styles.eventListTitleConatiner, Styles.eventListTitleConatinerCust)}>
                      <p className={css(Styles.eventListTitle, Styles.eventListTitleCust)}>{option.name}</p>
                      <div className={css(Styles.eventListDateContainer)}>
                        <p className={css(Styles.eventListDate, Styles.eventListDateCust)}>
                          {moment(option.event_time).format('MMM DD, YYYY, h:mm:ss A')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </Slider>
        </div>
      )
    }
    return ''
  }

  getEventItemHeaderContainerStyle = () => {
    if (this.props.independentEventDetails) {
      return css(Styles.eventItemHeaderContainer, Styles.eventItemHeaderContainerSpec)
    } else {
      return css(Styles.eventItemHeaderContainer)
    }
  }

  getEventItemOuterContainerStyle = () => {
    if (this.props.independentEventDetails) {
      return css(Styles.eventItemOuterContainer, Styles.eventItemOuterContainerSpec)
    } else {
      return css(Styles.eventItemOuterContainer)
    }
  }

  roundRect = (
    ctx,
    x,
    y,
    width,
    height,
    radius = 5,
    title,
    fill = false,
    stroke = true
  ) => {
    if (typeof radius === 'number') {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
    }

    ctx.beginPath();
    ctx.moveTo(x + width - radius.tr, y);
    ctx.lineTo(x + width, y);
    ctx.lineTo(x + width, y + radius.tr);

    ctx.moveTo(x + width, y + height - radius.br);
    ctx.lineTo(x + width, y + height);
    ctx.lineTo( x + width - radius.br, y + height);

    ctx.moveTo(x + radius.bl, y + height);
    ctx.lineTo(x, y + height);
    ctx.lineTo(x, y + height - radius.bl);

    ctx.moveTo(x, y + radius.tl);
    ctx.lineTo(x, y);
    ctx.lineTo( x + radius.tl, y);
    ctx.moveTo(x, y + radius.tl);

    ctx.closePath();
    ctx.font="15px kayak";
    ctx.shadowColor="white";
    ctx.strokeText(title? title.toLowerCase() :"", x , y-(20/2));
    ctx.shadowBlur=0;
    ctx.fillStyle = 'rgb(125,19,124)';
    ctx.fillText(title? title.toLowerCase() :"", x , y-(20/2));
    
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }

  onImgLoad = (e) => {
    if(!AppConfig.allowBoundingBox){
      return
    }
    const image = document.getElementById('source');
    var canvas = this.canvas.current
    const ctx = canvas.getContext("2d")
    const img = this.imageRef.current
    let bbox = JSON.parse(img.attributes.bbox.nodeValue)
    if(!bbox || bbox.length == 0 ){
      return
    }
    this.setState({
      showCanvas: true,
      imageInfo: e.target.src,
      eventInfoImgeHeight: e.target.clientHeight,
      eventInfoImgeWidth: e.target.clientWidth,
    }, () => {

      ctx.drawImage(image, 0, 0, this.state.eventInfoImgeWidth, this.state.eventInfoImgeHeight)
      ctx.beginPath();
      ctx.lineWidth = "3";
      ctx.strokeStyle = "white";
      let scale_o = image.naturalWidth / canvas.width
      for (let index = 0; index < bbox.length; index++) {
        let element = bbox[index];
        element.x = (parseFloat(element.xmin) / scale_o )
        element.y = (parseFloat(element.ymin) / scale_o )
        element.width =( parseFloat(element.xmax-element.xmin) / scale_o )
        element.height = (parseFloat(element.ymax-element.ymin) / scale_o )
        if(element.blur){
        ctx.filter = 'blur(' + 10 + 'px)';
        }else{
          ctx.filter = ""
        }
        ctx.drawImage(canvas,
          element.x, element.y, element.width, element.height,
          element.x, element.y, element.width, element.height
        );
        ctx.filter = 'none'; 
        ctx.fillStyle = 'rgba(255,255,255,0.2)';
        
        this.roundRect(ctx, element.x, element.y, element.width, element.height, 15, element.title)
        // ctx.rect( element.x, element.y, element.width, element.height, 18, element.title)

      }

      ctx.stroke();

    })
  }

  componentWillReceiveProps (newProps) {
    if(newProps && this.props && newProps.selectedEvent && this.props.selectedEvent && this.props.selectedEvent._id && newProps.selectedEvent._id &&  newProps.selectedEvent._id !== this.props.selectedEvent._id ) /* do stuff */{
      this.initializeCanvas()
    }
  }

  initializeCanvas = () =>{
    this.setState({
      showCanvas:false,
      imageInfo:"",
      eventInfoImgeHeight : 0,
      eventInfoImgeWidth : 0,
    })
  }
  
  renderEventInfo = (item) => {
    let itemToRender = item
    let imageUrl = item ? item.image : null
    if (this.props.selectedRelatedEvent) {
      itemToRender = this.props.selectedRelatedEvent
      imageUrl = this.props.selectedRelatedEvent.image
    }

    return (
      <div className={this.getEventItemOuterContainerStyle()}>
        <div className={this.getEventItemHeaderContainerStyle()}>
          {this.renderEventHeader(itemToRender)}

          {this.props.relativeImageSliderMode && (
            <div>
              <div>{this.renderRelativeImageSlider(itemToRender)}</div>
            </div>
          )}

          {!this.props.relativeImageSliderMode && imageUrl && (
            <div
              className={css(Styles.eventImageContent)}>
              {AppConfig.allowBoundingBox && <canvas ref={this.canvas} width={this.state.eventInfoImgeWidth} height={this.state.eventInfoImgeHeight} className={css(Styles.eventImage)} />
              }
              {this.state.showCanvas == false &&
                <img className={css(Styles.eventImage)} id='source' ref={this.imageRef}
                  onLoad={this.onImgLoad}
                  bbox={itemToRender.bbox ? JSON.stringify(itemToRender.bbox) : JSON.stringify([])}
                  key={imageUrl}
                  src={imageUrl}
                  onError={(e) => {
                    e.target.src = require("../../assets/images/error2.svg");
                  }}
                />
              }
            </div>
          )}

          {!this.props.independentEventDetails &&
            this.renderEventControls(item)}
        </div>

        <div
          className={
            this.props.relativeImageSliderMode
              ? css(Styles.eventItemFeatureContainerRelativeOpen)
              : css(Styles.eventItemFeatureContainer)
          }
        >
          {this.renderEventEditContainer(itemToRender)}

          {this.renderEvenImageSliderMini(itemToRender)}

          {this.renderEventSliderMini(item)}

          {AppConfig.allowEventReviews ? this.openCommentsModal() : ""}

          {AppConfig.allowEventReviews ? this.showLatestReviews() : ""}

          {AppConfig.allowEventReviews ? this.addNewComment() : ""}
        </div>
      </div>
    );
  }

  render = () => {
    return this.renderEventInfo(this.props.selectedEvent)
  }
}